<template>
    <div id="app">
        <div id="top">招生数据排行</div>
        <div class="index-float">
            <label style="margin-left:12%">请选择时间：</label>
            <a-radio-group default-value="0" button-style="solid" style="margin-right:12px" size="small" @change="radioChange">
                <a-radio-button value="0">
                    今天
                </a-radio-button>
                <a-radio-button value="1">
                    昨天
                </a-radio-button>
                <a-radio-button value="2">
                    最近7天
                </a-radio-button>
                <a-radio-button value="3">
                    最近30天
                </a-radio-button>
            </a-radio-group>
            <a-range-picker size="small" style="width:220px" @change="handleChange" :value="Time">
                <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                        {{ current.date() }}
                    </div>
                </template>
            </a-range-picker>
        
            <label style="margin-left:3%">请选择类型：</label>
            <a-select :default-value="DefaultType" class="a-select-type" @change="TypeQuery">
                <a-select-option key="-1">
                    全部
                </a-select-option>
                <a-select-option v-for="i in Type" :key="i.ID">
                    {{i.Name}}
                </a-select-option>
            </a-select>
        </div>
        <!--:pagination="{ pageSize: 50 }"--> <!--:scroll="{ y: 650 }" />-->
        <div class="tow" id="tow_da">
            <h2 style="font-weight:600;text-align:center;margin-top:10px;">招生平台数据访问排行榜</h2>
            <template>
                <a-table :columns="columns"
                         :data-source="DataVisit"
                         :pagination="false"
                         rowKey="VisitCustomerID"
                         :scroll="{ y: 650 }"/>

                <a-pagination class="tow-pagination" v-model="pageIndex" :total="VisitSum" @change="Visit()" style="margin-right:10%;" />
            </template>

        </div>
        <!--:pagination="{ pagesize: 50 }"--> <!--:scroll="{ y: 650 }"-->
        <div class="tow">
            <h2 style="font-weight:600;text-align:center;margin-top:10px;">招生平台数据拉新排行榜</h2>
            <template>
                <a-table :columns="columnd"
                         :data-source="DataNew"
                         :pagination="false"
                         rowKey="VisitCustomerID"
                         :scroll="{ y: 650 }"/>
                <a-pagination class="tow-pagination" v-model="pageIndex2" :total="DataSum" @change="NewRanking()" style="margin-right:10%;" />
            </template>
        </div>
    </div>
</template>
<script>
    const columns = [
        {
            title: '',
            dataIndex: '',
            width: 50,
        },
        {
            title: '序号',
            dataIndex: 'SerialNO',
            width: 100, 
        },
        {
            title: '机构名称',
            dataIndex: 'VisitName',
            width: 230,

        },
        {
            title: '机构类型',
            dataIndex: 'TypeName',
            width: 230,

        },
        {
            title: '访问数量',
            dataIndex: 'VisitCount',
            width: 100,
        },
    ];
    const columnd = [
        {
            title: '',
            dataIndex: '',
            width: 50,
        },
        {
            title: '序号',
            dataIndex: 'SerialNO',
            width: 100,
        },
        {
            title: '机构名称',
            dataIndex: 'VisitName',
            width: 230,

        },
        {
            title: '机构类型',
            dataIndex: 'TypeName',
            width: 230,

        },
        {
            title: '拉新数量',
            dataIndex: 'VisitCount',
            width: 100,
        },
    ];
    import "ant-design-vue/dist/antd.css";
    import Vue from "vue";
    import ant from "ant-design-vue";
    import util from "@/Plugin/util.js";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import moment from "moment";
    import http from "@/Plugin/Http.js";
    Vue.prototype.$zhCN = zh_CN;
    Vue.use(ant);


    export default {
        name: "",
        data() {
            return {
                columns,
                columnd,
           
                Time: [],
                BeginDate: util.formatDate(new Date()),
                EndDate: util.formatDate(new Date()),
                DataVisit: [],
                VisitSum:0,
                DataNew: [],
                DataSum:0,
                DataTransaction: [],
                pageSize:12,
                pageIndex: 1,
                pageIndex2: 1,
                Type: [],
                DefaultType: "全部",
                TypeID:-1,
            };
        },

        methods: {
            //类型选择器
            OrganizationType: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Ranking/OrganizationType',
                    OnSuccess: function (data) {
                        console.log(data.data);
                        self.Type = data.data;
                        console.log(self.Type);
                    }
                };
                http.Post(op);
            },
            TypeQuery: function (value) {
                var self = this;
                console.log(value);
                self.TypeID = value;
                self.Visit();
                self.NewRanking();
            },
            //方法
            Visit: function () {
                var self = this;
                console.log(self.BeginDate, self.EndDate, self.TypeID);
                var op = {
                    url: '/OperationsModule/Ranking/VisitRanking',
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        pageSize: self.pageSize,
                        pageIndex: self.pageIndex,
                        TypeID: self.TypeID,
                    },
                    OnSuccess: function (data) {
                        console.log("访问：",data);
                        self.DataVisit = data.data.Data;
                        self.VisitSum = data.data.Total;

                        //console.log(self.DataVisit);
                    }
                };
                http.Post(op);
            },
            NewRanking: function () {
                var self = this;
                var op = {
                    url: '/OperationsModule/Ranking/NewRanking',
                    data: {
                        BeginDate: self.BeginDate,
                        EndDate: self.EndDate,
                        pageSize: self.pageSize,
                        pageIndex: self.pageIndex2,
                        TypeID: self.TypeID,
                    },
                    OnSuccess: function (data) {
                        console.log("拉新：",data);
                        self.DataNew = data.data.Data;
                        self.DataSum = data.data.Total;
                    }
                };
                http.Post(op);
            },
  
            radioChange: function (item) {
                var self = this;
                var Time = "";
                switch (item.target.value) {
                    case "0":
                        Time = [
                            moment(new Date(), "YYYY-MM-DD"),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "1":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 1)),
                                "YYYY-MM-DD"
                            ),
                        ];
                        break;
                    case "2":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 7)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "3":
                        Time = [
                            moment(
                                new Date(new Date().setDate(new Date().getDate() - 30)),
                                "YYYY-MM-DD"
                            ),
                            moment(new Date(), "YYYY-MM-DD"),
                        ];
                        break;
                    case "4":
                        var year = new Date().getFullYear();
                        Time = [
                            moment(new Date(moment(year + "-01-01")), "YYYY-MM-DD"),
                            moment(new Date(moment(year + "-06-30")), "YYYY-MM-DD"),
                        ];
                        break;
                }
                self.handleChange(Time);
            },
            getCurrentStyle(current) {
                //console.log("型:");
                //console.log(today);
                const style = {};
                if (current.date() === 1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                }
                return style;
            },
            handleChange(value) {
                var self = this;
                if (value.length == 0) {
                    self.BeginDate = util.formatDate(new Date());
                    self.EndDate = util.formatDate(new Date());
                    value = [
                        moment(new Date(), "YYYY-MM-DD"),
                        moment(new Date(), "YYYY-MM-DD"),

                    ];
                    self.$message.error("必须选择日期", 1.5, function () { });
                } else {
                    self.BeginDate = util.formatDate(value[0]._d);
                    self.EndDate = util.formatDate(value[1]._d);
                }
                self.Time = value;
                self.Visit();
                self.NewRanking();
                console.log("时间");
                console.log(self.BeginDate, self.EndDate);
            },

        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件

        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            self.Time = [
                moment(new Date(), "YYYY-MM-DD"),
                moment(new Date(), "YYYY-MM-DD"),
            ];
            self.Visit();
            self.NewRanking();
            self.OrganizationType();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    }
</script>
<style>
    #app {
        background-color: #F0F2f5;
    }

    #top {
        text-align: center;
        color: #186ee5;
        font-size: 2.5em;
        font-weight: 600;
    }

    .index-float {
    }
    #tow_da {
    margin-left:8%;
    }
    .tow {
        width: 40%;
        height: 840px;
        float: left;
        background-color: #ffffff;
        margin-top: 20px;
        margin-left: 10%
    }
    .tow-paginationP {
    margin-right:10%;
    
    }
    .a-select-type {
      width: 100px;
      height:24px;
    }
</style>
